<template>
  <layout-selection-toolbar-teleport :selection-composable="selection" teleport-to-id="album-selection-toolbar">
    <ul class="list-unstyled flex gap-0 lg:gap-3">
      <li v-if="canMove">
        <core-selection-toolbar-button
          icon="i-ri-folder-transfer-line"
          label="Move"
          @click="moveAlbums"
        />
      </li>
      <li>
        <core-selection-toolbar-button
          :icon="COMMON_ICONS.delete"
          label="Delete"
          @click="deleteAlbums"
        />
      </li>
    </ul>
  </layout-selection-toolbar-teleport>
</template>

<script setup>
  const emit = defineEmits(['move-albums-complete', 'delete-albums-complete']);
  const props = defineProps({
    parentId: String,
    canMove: {
      type: Boolean,
      default: true
    }
  });

  const selection = useSelection(ALBUM_SELECTION_COMPOSABLE_KEY);
  const albumActions = useAlbumActions();

  async function moveAlbums() {
    await albumActions.move({
      albums: selection.selected.value,
      currentParentId: props.parentId
    });
    selection.clear();

    emit('move-albums-complete');
  }

  async function deleteAlbums() {
    await albumActions.deleteAlbums({
      albums: selection.selected.value
    });
    selection.clear();
    emit('delete-albums-complete');
  }
</script>
